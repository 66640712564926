import React from 'react';

import { AuthRole } from '@coachhubio/auth-role';
import { GetServerSideProps } from 'next';

import { LookerDashboardSideBar } from '../src/screens/Dashboard/LookerDashboardSideBar';
import { getAccessToken } from '../src/server/getAccessToken';
import { getUser } from '../src/server/getUser';

export default function Page() {
  const currentSection = 0;

  return <LookerDashboardSideBar currentSectionIndex={currentSection} />;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const user = getUser(context);
  const accessToken = getAccessToken(context);

  if (!accessToken || !user?.id || !user?.organizationId) {
    return {
      props: {},
    };
  }

  const isAuthorized = user?.roles?.has(AuthRole.OPERATIONS) || user?.roles?.has(AuthRole.CUSTOMER);
  let error = {};

  if (!isAuthorized) {
    error = {
      type: 'NotFound',
    };
  }

  return {
    props: {
      accessToken,
      error,
    },
  };
};
